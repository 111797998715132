import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';

import SignUp from '@/components/SignUp';

export function SignUpIndex() {
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/`);
    }
  }, [isAuthenticated]);

  return <SignUp />;
}

export default SignUpIndex;
