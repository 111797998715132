import SignUpView from '@/components/SignUp/SignUpView';

const defaultProps = {};

type SignUpContainerProps = {} & typeof defaultProps;

// eslint-disable-next-line no-empty-pattern
export function SignUpContainer({}: SignUpContainerProps) {
  return <SignUpView />;
}

SignUpContainer.defaultProps = defaultProps;

export default SignUpContainer;
